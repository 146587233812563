<template>
  <v-container class="mx-auto mx-lg-0 pt-0">
    <div v-if="loading" class="d-flex flex-column align-center justify-center mt-12">
      <h3 class="mb-8">Loading resources...</h3>
    </div>

    <template v-if="!loading && !resources && message">
      <v-container class="mt-7">
        <v-row class="align-center">
          <v-col class="text-center">
            <v-card
              v-if="message"
              outlined
              max-width="600"
              elevation="12"
              class="mx-auto py-8"
            >
              <v-card-text>
                <h3>
                  {{ message }}
                </h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-row v-if="!loading && resources">
      <v-col
        v-for="r in resources"
        :key="r.id"
        class="rs-resource col-12 col-sm-6 col-lg-4"
      >
        <a
          href="#"
          :title="r.title ? r.title : ''"
          class="d-block"
          @click.prevent="handleOpenResource(r)"
        >
          <v-card color="transparent">
            <v-responsive :aspect-ratio="16 / 9">
              <template>
                <div
                  class="h-full d-flex flex-column align-center justify-center pb-4 px-4 px-lg-6 text-center"
                  style="position: relative"
                >
                  <span class="mb-1 text-h6 font-weight-bold text-uppercase">
                    {{ r.title ? r.title : r.mediaType.toUpperCase() }}
                  </span>
                  <span v-if="r.subTitle"> {{ r.subTitle }}</span>

                  <div class="rs-resource-action d-flex align-center">
                    <v-icon color="primary">{{
                      r.videoId ? "mdi-play-box" : "mdi-download"
                    }}</v-icon
                    ><span class="ml-2 text-overline">{{
                      r.videoId ? "watch" : "download"
                    }}</span>
                  </div>
                  <!-- <img
                    v-if="r.thumbnailUrl"
                    :src="r.thumbnailUrl"
                    style="max-width: 100%"
                  />

                  <v-icon v-else style="font-size: 64px" color="secondary"
                    >{{ getMediaIcon(r.mediaType) }}
                  </v-icon> -->
                </div>
              </template>
            </v-responsive>
          </v-card>
        </a>
      </v-col>
    </v-row>
    <v-dialog
      v-if="currentVideoResource"
      v-model="showVideoDialog"
      persistent
      width="80%"
      max-width="1200"
    >
      <v-card class="pa-4">
        <v-responsive :aspect-ratio="16 / 9">
          <template v-if="currentVideoResource.mediaType == 'wistia'">
            <WistiaVideoEmbed :videoId="currentVideoResource.videoId" :autoPlay="false"
          /></template>
          <template v-if="currentVideoResource.mediaType == 'youtube'">
            <YoutubeVideoEmbed :videoId="currentVideoResource.videoId" :autoPlay="false"
          /></template>
        </v-responsive>
        <v-card-actions class="mt-4 justify-center">
          <v-btn
            @click="(showVideoDialog = false), (currentVideoResource = null)"
            color="primary"
            class="justify-self-end"
            >Close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import { mapGetters } from "vuex";
import WistiaVideoEmbed from "@/components/WistiaVideoEmbed.vue";
import YoutubeVideoEmbed from "@/components/YoutubeVideoEmbed.vue";
import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";
import { listResources } from "@/graphql/queries";

export default {
  mixins: [getUserCredentialsMixin],
  components: {
    WistiaVideoEmbed,
    YoutubeVideoEmbed,
  },
  data() {
    return {
      loading: false,
      message: "",
      resources: [],
      showVideoDialog: false,
      currentVideoResource: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    currentUser: {
      handler(val) {
        console.log("VAL", val);
        if (val?.organizationId) {
          this.getOrganizationResources(val.organizationId);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loading = true;
  },
  methods: {
    async getOrgResources(organizationId) {
      const query = /* GraphQL */ `
        query ListResourcesForOrg($organizationId: ID!) {
          listResources(filter: { organizationId: { eq: $organizationId } }) {
            items {
              id
              title
              subTitle
              mediaType
              urlTarget
              videoId
            }
          }
        }
      `;

      try {
        const response = await API.graphql(graphqlOperation(query, { organizationId }));
        return response.data.listResources.items;
      } catch (error) {
        console.error("Error fetching resources:", error);
        throw error;
      }
    },

    async getOrganizationResources(organizationId) {
      try {
        await this.getCredentials();
        let generalResourcesArray;
        let orgResourcesArray;

        const generalResources = await API.graphql(
          graphqlOperation(listResources, {
            filter: {
              organizationId: {
                attributeExists: false,
              },
              not: { organizationsToExclude: { contains: organizationId } },
            },
          })
        );

        console.log("generalResources", generalResources);

        if (generalResources.data?.listResources?.items) {
          generalResourcesArray = generalResources.data.listResources.items;
        } else {
          generalResourcesArray = [];
        }

        const orgResources = await this.getOrgResources(organizationId);

        // old method
        // const orgResources = await API.graphql(
        //   graphqlOperation(listResources, {
        //     filter: {
        //       organizationId: {
        //         eq: organizationId,
        //       },
        //       organizationsToExclude: {
        //         not: {
        //           organizationId: {
        //             eq: organizationId,
        //           },
        //         },
        //       },
        //     },
        //   })
        // );

        if (orgResources) {
          orgResourcesArray = orgResources;
        } else {
          orgResourcesArray = [];
        }

        generalResourcesArray.sort(function (a, b) {
          return a.displayOrder - b.displayOrder;
        });

        orgResourcesArray.sort(function (a, b) {
          return a.displayOrder - b.displayOrder;
        });

        const resources = orgResourcesArray.concat(generalResourcesArray);

        this.resources = resources;
      } catch (error) {
        console.log("Error querying customGetOrganizationResources.", error);
        this.message =
          "Sorry, an error has occurred. Please contact your organization for assistance.";
      } finally {
        this.loading = false;
      }
    },
    getMediaIcon(mediaType) {
      let icon;
      switch (mediaType) {
        case "pdf":
          icon = "mdi-file-pdf-box";
          break;
        case "file":
          icon = "mdi-file-document-outline";
          break;
        case "powerpoint":
          icon = "mdi-file-powerpoint-outline";
          break;
        default:
          icon = "mdi-file-document-outline";
          break;
      }

      return icon;
    },
    async handleOpenResource(resource) {
      if (resource.mediaType === "wistia" || resource.mediaType === "youtube") {
        this.showVideoDialog = true;
        this.currentVideoResource = resource;
      } else {
        try {
          // download file
          const storageKey = `${resource.s3Media.key}`;
          let filename;

          if (resource.title) {
            // use title and replace spaces adn slashes with hyphens
            filename = resource.title.replace(/[_\s/]/g, "-");
          }

          const result = await Storage.get(storageKey, { download: true });
          // console.log("result", result);
          if (
            !filename ||
            !result.ContentType ||
            result.ContentType === "binary/octet-stream"
          ) {
            filename = storageKey.split("/").pop();
          }

          this.downloadBlob(result.Body, filename);
        } catch (error) {
          console.log("Error handleOpenResource", error);
        }
      }
    },
    downloadBlob(blob, filename) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "download";
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);
      a.click();
      return a;
    },
    // async getMediaThumbnail(s3obj) {
    //   try {
    //     const storageKey = `${s3obj.key}`;

    //     const result = await Storage.get(storageKey, { download: false });
    //     console.log("thumbnail result", result);

    //     return result;
    //   } catch (error) {
    //     console.log("Error handleOpenResource", error);
    //   }
    // },
  },
};
</script>

<style lang="scss">
.rs-resource {
  a {
    text-decoration: none;
    background-color: white;
    span {
      color: #222;
    }

    &:hover,
    &:active,
    &:focus {
      span {
        color: #178af4;
      }

      .rs-resource-action {
        opacity: 1;
      }
      // img {
      //   opacity: 0.9;
      // }
    }

    .rs-resource-action {
      color: #178af4;
      opacity: 0;
      font-size: 32px;
      position: absolute;
      bottom: 0.75rem;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      transition: all 0.15s linear;
    }
  }
}
iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
